import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons'
import OpenSea from "../../assets/images/OSBlue.png"

const navigateTwitter = () => {
    const url = "https://twitter.com/limit___timil";
    window.open(url);
};
const navigateInstagram = () => {
    const url = "https://www.instagram.com/jimmylimit/?hl=en";
    window.open(url);
};

class Footer extends Component {
    render() {
        return (
            <footer className="acr-footer footer-2">
                {/* Footer Top Start */}
                {/*<App/>*/}
                {/* Footer Top End */}
                {/* Footer Middle Start */}

                {/* Footer Middle End */}
                {/* Footer Bottom Start */}
                <div className="footer-bottom">
                    <div className="container">
                        <div className="acr-cs-content-footer">
                                <p className="m-0 impact-black center-2" style={{color: 'white'}}>© Copyright 2022 - JIMMY LIMIT</p>
                            <div className='row'>
                                <div className='social-media center'>
                                    <a target="_blank" href="https://twitter.com/limit___timil" style={{color: 'white', paddingLeft: '10px', paddingRight: '10px'}}><FontAwesomeIcon icon={faTwitter} /></a>

                                    <a target="_blank" href="https://www.instagram.com/jimmylimit/?hl=en"style={{color: 'white', paddingLeft: '10px', paddingRight: '10px'}}><FontAwesomeIcon icon={faInstagram} /></a>

                                    <a
                          target="_blank"
                          href="https://opensea.io/collection/springsummer-collection-by-jimmy-limit"
                          style={{
                            fontSize: "100px",
                            color: "black",
                          }}
                        >
                          <img src={process.env.PUBLIC_URL + OpenSea} style={{width: '30px!important!'}} alt="logo" />
                        </a>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* Footer Bottom End */}
            </footer>
        );
    }
}

export default Footer;