import React, { Component, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons'
import { MdClose } from "react-icons/md";
import {GiHamburgerMenu} from "react-icons/gi";
import one from "../../../assets/images/gallery/1.jpg"
import two from "../../../assets/images/gallery/2.jpg"
import three from "../../../assets/images/gallery/3.jpg"
import four from "../../../assets/images/gallery/4.jpg"
import five from "../../../assets/images/gallery/5.jpg"
import six from "../../../assets/images/gallery/6.jpg"
import seven from "../../../assets/images/gallery/7.jpg"
import eight from "../../../assets/images/gallery/8.jpg"
import nine from "../../../assets/images/gallery/9.jpg"
import ten from "../../../assets/images/gallery/10.jpg"
import eleven from "../../../assets/images/gallery/11.jpg"
import twelve from "../../../assets/images/gallery/12.jpg"
import thirteen from "../../../assets/images/gallery/13.jpg"
import fourteen from "../../../assets/images/gallery/14.jpg"
import fifteen from "../../../assets/images/gallery/15.jpg"
import sixteen from "../../../assets/images/gallery/16.jpg"
import seventeen from "../../../assets/images/gallery/17.jpg"
import eighteen from "../../../assets/images/gallery/18.jpg"
import nineteen from "../../../assets/images/gallery/19.jpg"
import twenty from "../../../assets/images/gallery/20.jpg"
import twoone from "../../../assets/images/gallery/21.jpg"
import twotwo from "../../../assets/images/gallery/22.jpg"
import twothree from "../../../assets/images/gallery/23.jpg"
import twofour from "../../../assets/images/gallery/24.jpg"
import twofive from "../../../assets/images/gallery/25.jpg"
import twosix from "../../../assets/images/gallery/26.jpg"
import twoseven from "../../../assets/images/gallery/27.jpg"
import twoeight from "../../../assets/images/gallery/28.jpg"
import twonine from "../../../assets/images/gallery/29.jpg"
import thirty from "../../../assets/images/gallery/30.jpg"
import threeone from "../../../assets/images/gallery/31.jpg"
import threetwo from "../../../assets/images/gallery/32.jpg"
import threethree from "../../../assets/images/gallery/33.jpg"
import threefour from "../../../assets/images/gallery/34.jpg"
import threefive from "../../../assets/images/gallery/35.jpg"
import threesix from "../../../assets/images/gallery/36.jpg"
import threeseven from "../../../assets/images/gallery/37.jpg"
import threeeight from "../../../assets/images/gallery/38.jpg"
import threenine from "../../../assets/images/gallery/39.jpg"
import forty from "../../../assets/images/gallery/40.jpg"
import fourone from "../../../assets/images/gallery/41.jpg"
import four2 from "../../../assets/images/gallery/42.jpg"
import four3 from "../../../assets/images/gallery/43.jpg"
import four4 from "../../../assets/images/gallery/44.jpg"
import four5 from "../../../assets/images/gallery/45.jpg"
import four6 from "../../../assets/images/gallery/46.jpg"
import four7 from "../../../assets/images/gallery/47.jpg"
import four8 from "../../../assets/images/gallery/48.jpg"
import four9 from "../../../assets/images/gallery/49.jpg"
import fifty from "../../../assets/images/gallery/50.jpg"
import five1 from "../../../assets/images/gallery/51.jpg"
import five2 from "../../../assets/images/gallery/52.jpg"
import five3 from "../../../assets/images/gallery/53.jpg"
import five4 from "../../../assets/images/gallery/54.jpg"
import five5 from "../../../assets/images/gallery/55.jpg"
import five6 from "../../../assets/images/gallery/56.jpg"
import five7 from "../../../assets/images/gallery/57.jpg"
import five8 from "../../../assets/images/gallery/58.jpg"
import five9 from "../../../assets/images/gallery/59.jpg"
import sixty from "../../../assets/images/gallery/60.jpg"
import OpenSea from "../../../assets/images/opensea.png"




import serviceblock from '../../../data/team.json';


class Info extends Component {
    constructor(props) {
        super(props);

        this.scrollAbout = React.createRef();
        this.scrollRoadmap = React.createRef();
        this.scrollGallery = React.createRef();
        this.scrollFAQ = React.createRef();
        this.state = { navbarOpen: false };
    }

    closeMenu = () => {
        this.setState({navbarOpen: false})
      }

    navigateInstagram = () => {
        const url = "https://www.instagram.com/jimmylimit/?hl=en";
        window.open(url);
      };
    navigateTwitter = () => {
        const url = "https://twitter.com/limit___timil";
        window.open(url);
      };

    render() {
        const {navbarOpen} = this.state;
        return (
            <div>
                {/* INFO SECTION */}
            
            <header className="main-header header-3">

            <nav className="navbar">
                    <div className='spacers'>
                    <Link to="/home"  className="impact-black header-item bottom-align">
                                    JIMMY LIMIT
                    </Link>
                    </div>
                    <div className="row hamburger">

                        <div className="right-logo-mobile">
                            <nav className="navBar">
                            <button onClick={() => {
                                this.setState({ navbarOpen: !navbarOpen})
                            }}>{navbarOpen ? (
                                <MdClose style={{width: "25px", height: "25px"}} />
                            ) : (
                                <GiHamburgerMenu style={{ width: "25px", height: "25px"}} />
                            )}
                            </button>
                            <ul className={`menuNav ${navbarOpen ? " showMenu" : ""}`}>
                            <div className="impact-black header-item active-link"
                                style={{paddingLeft: '51px', marginTop: '28.5px'}}>
                                    JIMMY LIMIT
                                </div>
                                <div className='custom-hr'></div>
                                <Link
                                to="/home"
                                className="active-link"
                                style={{ color: '#71d1eb'}}
                                onClick={() => {this.closeMenu(); this.scrollAbout.current.scrollIntoView({behavior: 'smooth'});}}
                                exact
                                >
                                HOME
                                </Link>
                                <div className="custom-hr"></div>
                                

                                <div>
                                </div>                 
                                <div className="custom-hr"></div>
                                <div className="social-media media-hamburger center " style={{marginTop: '20px'}}>
                                    <li>
                                    <a target="_blank" href="https://twitter.com/limit___timil" style={{fontSize: '30px', color: 'black', paddingLeft: '10px', paddingRight: '10px'}}><FontAwesomeIcon icon={faTwitter} /></a>
                                    </li>
                                    <li>
                                    <a target="_blank" href="https://www.instagram.com/jimmylimit/?hl=en"style={{fontSize: '30px', color: 'black', paddingLeft: '10px', paddingRight: '10px'}}><FontAwesomeIcon icon={faInstagram} /></a>

                                    </li>
                                    <li>
                        <a
                          target="_blank"
                          href="https://opensea.io/collection/springsummer-collection-by-jimmy-limit"
                          style={{
                            fontSize: "100px",
                            color: "black",
                          }}
                        >
                          <img src={process.env.PUBLIC_URL + OpenSea} style={{width: '30px!important!'}} alt="logo" />
                        </a>
                      </li>
                                </div>
                                <div
                                className="btn-custom"
                                style={{width: '200px', marginTop: '40px', backgroundColor: 'black'}}
                                onClick={() => this.closeMenu()}
                                exact>
                                Close
                                </div>
                                <br></br>

                            </ul>
                            </nav>
                        </div>
                    
                    </div>
                    <div>
                    <ul className="header-controls-inner d-none d-lg-flex">
                        <div className = "spacers">
                            <Link to="/home" className="impact-black header-item" style={{ color: '#71d1eb'}} onClick={() => {this.scrollAbout.current.scrollIntoView({behavior: 'smooth'});}}>
                                HOME
                            </Link>
                        </div>


                        <div className='spacers'>
                            <a target="_blank" href="https://twitter.com/limit___timil" className='impact-black header-item-a'><FontAwesomeIcon icon={faTwitter} /></a>
                        </div>
                        <div className='spacers'>
                            <a target="_blank" href="https://www.instagram.com/jimmylimit/?hl=en" className='impact-black header-item-a'><FontAwesomeIcon icon={faInstagram} /></a>
                        </div>
                        <div className="spacers">
                <a
                    target="_blank"
                    href="https://opensea.io/collection/springsummer-collection-by-jimmy-limit"
                    className="impact-black header-item-a"
                    style={{ paddingTop: "10px" }}
                >
                    <img src={process.env.PUBLIC_URL + OpenSea} style={{width: '30px'}} alt="logo" />
                </a>
                

              </div>
                    </ul>
                    
                    </div>

                </nav>
                </header>


                <div className="section-cs infographics-2 bg-norepeat bg-bottom">
                    <div className="acr-cs-container">
                        <div className="acr-cs-content-small">
                        {/* SERVICES SECTION */}

                                <div className='row'>
                                    <div className='col-lg-1'></div>
                                    <div className="col-lg-10">
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + one} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + two} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + three} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + four} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + five} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + six} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + seven} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + eight} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + nine} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + ten} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + eleven} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + twelve} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + thirteen} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "auto", maxHeight: '250px'}} src={process.env.PUBLIC_URL + fourteen} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + fifteen} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + sixteen} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + seventeen} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + eighteen} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + nineteen} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + twenty} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + twoone} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + twotwo} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + twothree} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + twofour} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + twofive} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + twosix} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + twoseven} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + twoeight} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + twonine} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + thirty} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + threeone} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + threetwo} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + threethree} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + threefour} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + threefive} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + threesix} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + threeseven} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + threeeight} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + threenine} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + forty} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + fourone} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + four2} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + four3} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + four4} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + four5} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + four6} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + four7} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + four8} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + four9} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + fifty} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + five1} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + five2} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + five3} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + five4} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + five5} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + five6} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + five7} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "250px"}} src={process.env.PUBLIC_URL + five8} alt="logo" />
                                    <img style={{width: "null", resizeMode: "contain", height: "auto", maxHeight: '250px'}} src={process.env.PUBLIC_URL + five9} alt="logo" />




                                    </div>

                                </div>
                    
                    </div>
                </div>
               
     
              


                </div>
        </div>
        );
    }
}

export default Info;