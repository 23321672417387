import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'


import Mint from './components/pages/Mint'
import LandingPage from './components/pages/LandingPage'
import Gallery from './components/pages/Gallery'


const App = () => {
  return (
    <Router>

        <Routes>
          <Route exact path="/" element={<LandingPage/>}/>
          <Route path="/mint12345678x" element={<Mint/>}/>
          <Route path="/home" element={<LandingPage/>}/>
          <Route path="/gallery" element={<Gallery/>}/>

        </Routes>

    </Router>
  );
}

export default App;