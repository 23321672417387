import React, { Component, useRef } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import serviceblock from "../../../data/team.json";
import JimmyGif from "../../../assets/images/jimmygif.gif";
import Self from "../../../assets/images/self.jpeg";
import { Accordion, Card, NavLink } from "react-bootstrap";
import JimmyGallery from "../../../assets/images/jimmygallery.jpeg";
import { MdClose } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import Magazine from "../../../assets/images/magazine.jpeg";
import OpenSea from "../../../assets/images/opensea.png"

class Info extends Component {
  constructor(props) {
    super(props);

    this.scrollAbout = React.createRef();
    this.scrollRoadmap = React.createRef();
    this.scrollGallery = React.createRef();
    this.scrollFAQ = React.createRef();
    this.scrollArtist = React.createRef();
    this.state = { navbarOpen: false };
  }

  closeMenu = () => {
    this.setState({ navbarOpen: false });
  };

  navigateInstagram = () => {
    const url = "https://www.instagram.com/jimmylimit/?hl=en";
    window.open(url);
  };
  navigateTwitter = () => {
    const url = "https://twitter.com/limit___timil";
    window.open(url);
  };
  navigateOS = () => {
    const url = "https://opensea.io/collection/springsummer-collection-by-jimmy-limit";
    window.open(url);
  }

  render() {
    const { navbarOpen } = this.state;
    return (
      <div>
        {/* INFO SECTION */}

        <header className="main-header header-3">
          <nav className="navbar">
            <div className="spacers">
              <Link
                to="/home"
                className="impact-black header-item bottom-align"
              >
                JIMMY LIMIT
              </Link>
            </div>
            <div className="row hamburger">
              <div className="right-logo-mobile">
                <nav className="navBar">
                  <button
                    onClick={() => {
                      this.setState({ navbarOpen: !navbarOpen });
                    }}
                  >
                    {navbarOpen ? (
                      <MdClose style={{ width: "25px", height: "25px" }} />
                    ) : (
                      <GiHamburgerMenu
                        style={{ width: "25px", height: "25px" }}
                      />
                    )}
                  </button>
                  <ul className={`menuNav ${navbarOpen ? " showMenu" : ""}`}>
                    <div
                      className="impact-black header-item active-link"
                      style={{ paddingLeft: "51px", marginTop: "28.5px" }}
                    >
                      JIMMY LIMIT
                    </div>
                    <div className="custom-hr"></div>
                    <div
                      className="active-link"
                      style={{ color: "#71d1eb" }}
                      onClick={() => {
                        this.closeMenu();
                        this.scrollAbout.current.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                      exact
                    >
                      ABOUT
                    </div>
                    <div className="custom-hr"></div>
                    <div
                      className="active-link"
                      style={{ color: "#ffa02f" }}
                      onClick={() => {
                        this.closeMenu();
                        this.scrollRoadmap.current.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                      exact
                    >
                      ROADMAP
                    </div>
                    <div className="custom-hr"></div>
                    <div
                      className="active-link"
                      style={{ color: "#51b365" }}
                      onClick={() => {
                        this.closeMenu();
                        this.scrollArtist.current.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                      exact
                    >
                      ARTIST
                    </div>
                    <div className="custom-hr"></div>
                    <div
                      className="active-link"
                      style={{ color: "#ff0008" }}
                      onClick={() => {
                        this.closeMenu();
                        this.scrollFAQ.current.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                      exact
                    >
                      FAQS
                    </div>
                    <div className="custom-hr"></div>
                    <Link
                      to="/gallery"
                      className="active-link"
                      style={{ color: "#faea57" }}
                      onClick={() => this.closeMenu()}
                      exact
                    >
                      GALLERY
                    </Link>
                    <div></div>
                    <div className="custom-hr"></div>
                    <div
                      className="social-media media-hamburger center "
                      style={{ marginTop: "20px" }}
                    >
                      <li>
                        <a
                          target="_blank"
                          href="https://twitter.com/limit___timil"
                          style={{
                            fontSize: "30px",
                            color: "black",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          <FontAwesomeIcon icon={faTwitter} />
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.instagram.com/jimmylimit/?hl=en"
                          style={{
                            fontSize: "30px",
                            color: "black",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          <FontAwesomeIcon icon={faInstagram} />
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://opensea.io/collection/springsummer-collection-by-jimmy-limit"
                          style={{
                            fontSize: "100px",
                            color: "black",
                          }}
                        >
                          <img src={process.env.PUBLIC_URL + OpenSea} style={{width: '30px!important!'}} alt="logo" />
                        </a>
                      </li>
                    </div>
                    <div
                      className="btn-custom"
                      style={{
                        width: "200px",
                        marginTop: "40px",
                        backgroundColor: "black",
                      }}
                      onClick={() => this.closeMenu()}
                      exact
                    >
                      Close
                    </div>
                    <br></br>
                  </ul>
                </nav>
              </div>
            </div>
            <ul className="header-controls-inner d-none d-lg-flex">
              <div className="spacers">
                <a
                  className="impact-black header-item"
                  style={{ color: "#71d1eb" }}
                  onClick={() => {
                    this.scrollAbout.current.scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                >
                  ABOUT
                </a>
              </div>
              <div className="spacers">
                <a
                  className="impact-black header-item"
                  style={{ color: "#ffa02f" }}
                  onClick={() => {
                    this.scrollRoadmap.current.scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                >
                  ROADMAP
                </a>
              </div>
              <div className="spacers">
                <a
                  className="impact-black header-item"
                  style={{ color: "#51b365" }}
                  onClick={() => {
                    this.scrollArtist.current.scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                >
                  ARTIST
                </a>
              </div>
              <div className="spacers">
                <a
                  className="impact-black header-item"
                  style={{ color: "#ff0008" }}
                  onClick={() => {
                    this.scrollFAQ.current.scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                >
                  FAQS
                </a>
              </div>
              <div className="spacers">
                <Link
                  to="/gallery"
                  className="impact-black header-item"
                  style={{ color: "#faea57", paddingTop: "10px" }}
                >
                  GALLERY
                </Link>
              </div>
              <div className="spacers">
                <a
                  target="_blank"
                  href="https://twitter.com/limit___timil"
                  className="impact-black header-item-a"
                  style={{ paddingTop: "10px" }}
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </div>
              <div className="spacers">
                <a
                  target="_blank"
                  href="https://www.instagram.com/jimmylimit/?hl=en"
                  className="impact-black header-item-a"
                  style={{ paddingTop: "10px" }}
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                </div>
                <div className="spacers">
                <a
                    target="_blank"
                    href="https://opensea.io/collection/springsummer-collection-by-jimmy-limit"
                    className="impact-black header-item-a"
                    style={{ paddingTop: "10px" }}
                >
                    <img src={process.env.PUBLIC_URL + OpenSea} style={{width: '30px'}} alt="logo" />
                </a>
                

              </div>
            </ul>
          </nav>
        </header>

        <div className="acr-cs-container-seller ">
          {" "}
          {/*black-bg*/}
          <div className="acr-cs-content">
            <div className="">
              <div className="col-lg-8 offset-lg-2">
                <div className="huge-top-margin center">
                  <div
                    className="impact-black-mobile center"
                    style={{ lineHeight: "200%" }}
                  >
                    JIMMY LIMIT <br></br>SPRING/SUMMER COLLECTION
                  </div>
                  <br></br>
                  <br></br>
                  <div
                    className="btn-custom btn-lg impact-black"
                    style={{
                      borderRadius: "0.3rem",
                      width: "300px",
                      border: "2px solid red",
                      backgroundColor: "#71d1eb",
                      color: "red",
                      paddingTop: "20px",
                    }}
                  >
                    <a
                      target="_blank"
                      href="https://mint.springsummer.xyz/"
                      className="impact-black"
                      style={{
                        paddingTop: "10px",
                        color: "red",
                        fontSize: "40px",
                      }}
                    >
                      MINTING NOW!
                    </a>
                  </div>

                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>

                  <img src={process.env.PUBLIC_URL + JimmyGif} alt="logo" />
                  {/*<img src={process.env.PUBLIC_URL + LargeLogo} alt="logo" />*/}
                </div>
              </div>
              <br></br>
              <br></br>
              <div className="col-lg-8 offset-lg-2">
                <br></br>
              </div>
            </div>
          </div>
        </div>

        {/* About */}
        <div ref={this.scrollAbout} id="About"></div>
        <div
          className="section-cs light-bg infographics-2 bg-norepeat bg-bottom"
          style={{ backgroundColor: "rgb(255, 255, 255)" }}
        >
          <div className="acr-cs-container">
            <div className="acr-cs-content-small">
              {/* SERVICES SECTION */}
              <div className="section-title-wrap section-header">
                <h2 className="impact-black" style={{ color: "#71d1eb" }}>
                  ABOUT
                </h2>
              </div>
              <div className="row">
                <div className="col-lg-6 center-2">
                  <h6 className="gothic">
                    Spring/Summer is a collection of 67 photos inspired by the
                    aesthetics of online catalog photography which explores the
                    relationship between desire and digital consumption. The
                    images feature assemblages that combine hardware store
                    goods, homemade ceramics, organic elements and various
                    mass-produced detritus, shot on brightly colored solid
                    backgrounds. The playful images appropriate the bright
                    commercial non-aesthetic associated with advertising and
                    material commodity culture, reminiscent of stock photography
                    and industrial supply catalogs; two references that are
                    recognizable yet ambiguous.
                    <br></br>
                    <br></br>
                    This work has previously been published in an artist book
                    modeled after the ULINE Catalog, exhibited as a gallery
                    installation, and presented in public spaces typically used
                    for advertising. The collection in this context offers an
                    important opportunity for the images to exist in their
                    original digital state, which aligns most closely with the
                    intended concept for the series. Instead of photography
                    being the purveyor of commodities, or precursor to physical
                    exchange, here the image becomes the commodity itself meant
                    to be collected, traded and speculated on.
                    <br></br>
                    <br></br>
                    Inspired by both 1/1 photography collections and PFPs, each
                    digital photograph will be randomly minted, have attached
                    traits, and provide holders with future benefits.
                    <br></br>
                    <br></br>
                    Holders will be eligible for:
                    <br></br>
                    <br></br>• One copy of the artist book Spring/Summer 2012
                    (must provide mailing address). <br></br>• Free mint of
                    Jimmy's future secretly-in-the-works larger generative PFP
                    photography project.<br></br>• Advance news and access to
                    future collections. <br></br>
                  </h6>
                </div>
                <div className="col-lg-6 center">
                  <img
                    src={process.env.PUBLIC_URL + Magazine}
                    style={{ width: "400px" }}
                    alt="logo"
                  />
                </div>

                <div className="col-lg-12 center">
                  <br></br>
                  <br></br>
                  <a
                    target="_blank"
                    href="https://canadianart.ca/reviews/jimmy-limit-clint-roenisch/"
                    className="impact-black  adaptive-font"
                  >
                    Canadian Art |{" "}
                  </a>
                  <a
                    target="_blank"
                    href="https://canadianart.ca/reviews/jimmy-limit-show-room/"
                    className="impact-black adaptive-font"
                  >
                    Canadian Art 2 |{" "}
                  </a>
                  <a
                    target="_blank"
                    href="https://www.printedmatter.org/catalog/33708/"
                    className="impact-black adaptive-font"
                  >
                    Printed Matter |{" "}
                  </a>
                  <a
                    target="_blank"
                    href="https://www.moussemagazine.it/magazine/jimmy-limit-clint-roenisch/"
                    className="impact-black adaptive-font"
                  >
                    Mousse Magazine |{" "}
                  </a>
                  <a
                    target="_blank"
                    href="https://curamagazine.com/product/cura-15/"
                    className="impact-black adaptive-font"
                  >
                    Cura Magazine |{" "}
                  </a>
                  <a
                    target="_blank"
                    href="https://cmagazine.com/issues/118"
                    className="impact-black adaptive-font"
                  >
                    C Magazine |{" "}
                  </a>
                  <a
                    target="_blank"
                    href="https://www.sfaq.us/2013/02/review-show-room-by-jimmy-limit-at-clint-roenisch-toronto/"
                    className="impact-black adaptive-font"
                  >
                    SFAQ |{" "}
                  </a>
                  <a
                    target="_blank"
                    href="https://www.sfaq.us/2013/02/review-show-room-by-jimmy-limit-at-clint-roenisch-toronto/"
                    className="impact-black adaptive-font"
                  >
                    SFAQ |{" "}
                  </a>
                  <a
                    target="_blank"
                    href="https://www.jimmylimit.com"
                    className="impact-black adaptive-font"
                  >
                    Artist's Website |{" "}
                  </a>
                  <a
                    target="_blank"
                    href="https://rare.makersplace.com/2021/11/17/something-new-and-strange-jimmy-limit/#:~:text=Jimmy%20Limit%20is%20a%20contemporary,in%20Frieze%20Magazine%20in%20London."
                    className="impact-black adaptive-font"
                  >
                    MakersPlace Feature |{" "}
                  </a>
                  <a
                    target="_blank"
                    href="https://foundation.app/@JimmyLimit"
                    className="impact-black adaptive-font"
                  >
                    Foundation |{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* The Roadmap */}
        <div ref={this.scrollRoadmap} id="Roadmap"></div>
        <div
          className="section-cs light-bg infographics-2 bg-norepeat bg-bottom"
          style={{ backgroundColor: "rgb(255, 255, 255)" }}
        >
          <div className="acr-cs-container">
            <div className="acr-cs-content-small">
              {/* SERVICES SECTION */}
              <div className="section-title-wrap section-header">
                <h2
                  className="impact-black right-align"
                  style={{ color: "#ffa02f" }}
                >
                  ROADMAP
                </h2>
              </div>
              <div className="row">
                <div className="col-lg-6 center-top">
                  <img
                    src={process.env.PUBLIC_URL + JimmyGallery}
                    className="center-top-img weekly-sample-container"
                    alt="logo"
                  />
                </div>
                <div className="col-lg-6 center">
                  <Accordion defaultActiveKey="0" className="with-gap">
                    <Card>
                      <Accordion.Collapse
                        eventKey="0"
                        className="collapseparent"
                      >
                        <Card.Body className="cyan-back">
                          <p className="impact-black-2 faq-font">
                            {" "}
                            67 piece photo collection will be available to mint
                            May 17th at 11 A.M. EST. <br></br>
                            <br></br>A portion of the proceeds from each minted
                            photograph will be directed towards carbon
                            offsetting.
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                      <Card.Header>
                        <Accordion.Toggle
                          as={NavLink}
                          variant="link"
                          eventKey="0"
                        >
                          Phase 1: Initial Mint
                        </Accordion.Toggle>
                      </Card.Header>
                    </Card>
                    <Card>
                      <Accordion.Collapse
                        eventKey="1"
                        className="collapseparent"
                      >
                        <Card.Body className="yellow-back">
                          <p className="impact-black-2 faq-font">
                            {" "}
                            Creation of Discord channel for all holders once
                            32.1% sold. <br></br>
                            <br></br>
                            This community will include information on and early
                            access to future releases. Other things will happen
                            there too! Like, trading and sharing mint reveals?
                            Uhhhh, jokes? Celebrity guests? Discussions about TV
                            shows? We'll be working to provide future value to
                            all holders, with a primary focus of creating an
                            active secondary market for the work.
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                      <Card.Header>
                        <Accordion.Toggle
                          as={NavLink}
                          variant="link"
                          eventKey="1"
                        >
                          Phase 2: Community Development
                        </Accordion.Toggle>
                      </Card.Header>
                    </Card>
                    <Card>
                      <Accordion.Collapse
                        eventKey="2"
                        className="collapseparent"
                      >
                        <Card.Body className="pink-back">
                          <p className="impact-black-2 faq-font">
                            {" "}
                            A portion of sales will help fund the development of
                            a generative photography PFP project using my bright
                            colorful studio aesthetic - to be publicly announced
                            June 2022 (tentative).
                            <br></br>
                            <br></br>
                            This in addition to continuing to create 1/1
                            artworks including minting a collection of short
                            video works to Foundation and adding to the existing
                            photo collection Egg Town. I will also begin to post
                            more experimental work engaging with GAN and
                            photography on Teia.art.
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                      <Card.Header>
                        <Accordion.Toggle
                          as={NavLink}
                          variant="link"
                          eventKey="2"
                        >
                          Phase 3: PFP Collection
                        </Accordion.Toggle>
                      </Card.Header>
                    </Card>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Gallery */}
        {/*
                <div ref={this.scrollGallery} id="Gallery"></div>
                <div className="section-cs light-bg infographics-2 bg-norepeat bg-bottom" style={{ backgroundColor: "rgb(255, 255, 255)"}}>
                    <div className="acr-cs-container">
                        <div className="acr-cs-content-small background-photo">

                            <div className="section-title-wrap section-header">
                                <h2 className="impact-black">Gallery</h2>
                            </div>
                        <div className="row">
                            {serviceblock.map((item, i) => (
                                <div key={i} className="col-lg-3 col-md-6">
                                    <div className="acr-infographic-item">

                                        <img src={require("../../../assets/images/factions/" + item.icon).default} />
                                        <h5 className='impact-black bigger-font'>{item.title}</h5>
                                        <h6 className="impact-black">{item.field}</h6>

                                        <p className='gold left-justify'>{item.text}</p>

                                        <br></br>
                             
                                    </div>
                                </div>
                            ))}
                        </div>
                        </div>
                    </div>
                </div>
                            */}
        {/* Artist */}
        <div ref={this.scrollArtist} id="Artist"></div>
        <div
          className="section-cs light-bg infographics-2 bg-norepeat bg-bottom"
          style={{ backgroundColor: "rgb(255, 255, 255)" }}
        >
          <div className="acr-cs-container">
            <div className="acr-cs-content-small">
              {/* SERVICES SECTION */}
              <div className="section-title-wrap section-header">
                <h2 className="impact-black" style={{ color: "#51b365" }}>
                  ARTIST
                </h2>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <h6 className="gothic">
                    Jimmy Limit is a photo based artist working in installation,
                    ceramics, sculpture and crypto. <br></br>
                    <br></br>
                    He has exhibited in solo and group exhibitions throughout
                    Canada and the United States including Albright Knox Art
                    Gallery, Buffalo, Temnikova & Kasela, Tallinn Estonia,
                    Rodman Hall St. Catharines, Clint Roenisch Gallery, Toronto
                    and Printed Matter, NYC.
                    <br></br>
                    <br></br>
                    His work has been published in The New York Times, Frieze
                    Magazine and has been featured on the covers of C Magazine
                    and cura.
                    <br></br>
                    <br></br>
                    Recent public works have included Photos for a Project in
                    Progress at the Bentway in Toronto and Photos for Vacant
                    Storefronts, for CAFKA16 in Kitchener, ON.
                    <br></br>
                    <br></br>
                    He was born in Toronto Canada.
                  </h6>
                </div>
                <div className="col-lg-6 center">
                  <img src={process.env.PUBLIC_URL + Self} alt="logo" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FAQs */}
        <div ref={this.scrollFAQ} id="faq"></div>
        <div
          className="section-cs light-bg infographics-2 bg-norepeat bg-bottom"
          style={{ backgroundColor: "rgb(255, 255, 255)" }}
        >
          <div className="acr-cs-container">
            <div className="acr-cs-content-small">
              {/* SERVICES SECTION */}
              <div className="section-title-wrap section-header">
                <h2
                  className="impact-black right-align"
                  style={{ color: "#ff0008" }}
                >
                  FAQS
                </h2>
              </div>
              <Accordion defaultActiveKey="0" className="with-gap">
                <Card>
                  <Accordion.Collapse eventKey="0" className="collapseparent">
                    <Card.Body className="green-back">
                      <p className="impact-black-2 faq-font">
                        {" "}
                        There will be a total of 67 1/1 NFTs in the
                        Spring/Summer collection, with one reserved for the
                        artist's vault and one as a giveaway.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                  <Card.Header>
                    <Accordion.Toggle as={NavLink} variant="link" eventKey="0">
                      What's the total supply?
                    </Accordion.Toggle>
                  </Card.Header>
                </Card>
                <Card>
                  <Accordion.Collapse eventKey="1" className="collapseparent">
                    <Card.Body className="cyan-back">
                      <p className="impact-black-2 faq-font">
                        {" "}
                        Each NFT will be available for 0.123 ETH + gas fees.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                  <Card.Header>
                    <Accordion.Toggle as={NavLink} variant="link" eventKey="1">
                      What's the Mint Price?
                    </Accordion.Toggle>
                  </Card.Header>
                </Card>
                <Card>
                  <Accordion.Collapse eventKey="2" className="collapseparent">
                    <Card.Body className="orange-back">
                      <p className="impact-black-2 faq-font">
                        {" "}
                        The official mint time is May 17th at 11 A.M. EST!
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                  <Card.Header>
                    <Accordion.Toggle as={NavLink} variant="link" eventKey="2">
                      When's the Mint Date?
                    </Accordion.Toggle>
                  </Card.Header>
                </Card>
                <Card>
                  <Accordion.Collapse eventKey="3" className="collapseparent">
                    <Card.Body className="pink-back">
                      <p className="impact-black-2 faq-font"> No limit.</p>
                    </Card.Body>
                  </Accordion.Collapse>
                  <Card.Header>
                    <Accordion.Toggle as={NavLink} variant="link" eventKey="3">
                      How many NFTs can I mint?
                    </Accordion.Toggle>
                  </Card.Header>
                </Card>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Info;
