import React, { Component, Fragment} from 'react';
import Info from './Info';

class Content extends Component {
    render() {
        return (
            <Fragment>
                <Info/>
            </Fragment>
        );
    }
}

export default Content;